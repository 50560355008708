export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1588575168/chickochicken/about.jpg'
export const aboutParallax =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623632720/CnTChicken/aboutParallax.jpg'
export const cflogo =
  'https://res.cloudinary.com/chicko/image/upload/f_auto,q_auto/v1615848013/chicko/cflogo.png'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623633628/CnTChicken/contactbg.jpg'
export const downloadAppButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623631579/CnTChicken/downloadAppButton.png'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623632824/CnTChicken/foodGallery.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623631116/CnTChicken/hero.jpg'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623631580/CnTChicken/heroText.png'
export const homebg =
  'https://res.cloudinary.com/chicko/image/upload/f_auto,q_auto/v1615848015/chicko/homebgChicko2.jpg'
export const locationMap =
  'https://res.cloudinary.com/chicko/image/upload/f_auto,q_auto/v1615848015/chicko/locationMap.jpg'
export const locationMapBurrard =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623632872/CnTChicken/locationMapVan.jpg'
export const locationMapRichmond =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623633529/CnTChicken/locationMapRichmond.jpg'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623631221/CnTChicken/logo.png'
export const logoTitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623631268/CnTChicken/logoTitle.png'
export const mobileAbout =
  'https://res.cloudinary.com/chicko/image/upload/f_auto,q_auto/v1615848016/chicko/mobileAbout.png'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623634892/CnTChicken/contactbgMobile.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623634553/CnTChicken/mobileGallery.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623633844/CnTChicken/mobileHero.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623633912/CnTChicken/mobileHeroText.png'
export const mobileMap =
  'https://res.cloudinary.com/chicko/image/upload/f_auto,q_auto/v1615848017/chicko/mobileMap.jpg'
export const mobileMapLansdowne =
  'https://res.cloudinary.com/chicko/image/upload/f_auto,q_auto/v1615848017/chicko/mobileMapLansdowne.jpg'
export const mobileMapBurrard =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623634846/CnTChicken/mobileMapBurrard.jpg'
export const mobileMapParallax =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623634394/CnTChicken/mobileParallax.jpg'
export const mobileMapRichmond =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623635063/CnTChicken/mobileMapRichmond.jpg'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623634320/CnTChicken/promotionsMobile.jpg'
export const orderButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1588575172/chickochicken/orderButton.png'
export const orderDeliveryButton =
  'https://res.cloudinary.com/chicko/image/upload/f_auto,q_auto/v1615848018/chicko/orderDeliveryButton.png'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623631580/CnTChicken/orderPickupButton.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623632594/CnTChicken/promotions.jpg'
export const mobileLocationsBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1623635257/CnTChicken/bgSelect.jpg'

import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, mobileLogoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/jwtXW2Bc6tUEEYtcnIIt/locations/MvLV4OUJjaddWhfyWwXM'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center h="85px">
          <CFView row center>
            <CFImage
              h="65px"
              mt="5px"
              src={logo}
              alt="C&T Fried Chicken Logo"
            />
            {/* <CFImage
              h="35px"
              ml="10px"
              mt="5px"
              src={mobileLogoTitle}
              alt="C&T Fried Chicken Logo Title"
            /> */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          h="55px"
          bg="rgba(0,0,0,.7)"
          zIndex={98}
          w="100%"
        >
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="30px"
          >
            <CFView row justifyStart mt="75px">
              <CFImage
                h="130px"
                mt="15px"
                src={logo}
                alt="C&T Fried Chicken Logo"
                zIndex={98}
              />
              <CFImage
                h="25px"
                mt="22.5px"
                ml="15px"
                src={logoTitle}
                alt="C&T Fried Chicken Logo Title"
              />
              <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
                <CFView mt="15px" zIndex={99}>
                  <LocationSelect />
                </CFView>
              </CFSelect>
            </CFView>
            {/* <CFView hover mt="12px">
              <CFLink href={orderingUrl}>
                <CFImage
                  h="60px"
                  src={viewMenu}
                  alt="C&T Fried Chicken View Menu Button"
                />
              </CFLink>
            </CFView> */}
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

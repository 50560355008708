import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { mobileMapParallax, about, aboutParallax } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <Parallax
          bgImage={mobileMapParallax}
          bgImageAlt="Entree Dish"
          strength={300}
        >
          <div style={{ height: '400px' }} />
        </Parallax>
        {/* <CFView
          column
          center
          h="400px"
          image={`url(${mobileMapParallax}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView> */}
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          pv="10%"
          pr="40px"
          maxWidth="1400px"
          h="600px"
          image={`url(${aboutParallax}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}

import React from 'react'
import {
  CFView,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="93vh"
          w="100%"
          image={`url(${mobileHero}) top/ cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyStart
        >
          <CFView column center alignCenter>
            <CFImage src={mobileHeroText} w="90%" alt="About" mv="30px" />
            <CFView column justifyBetween alignCenter ph="10px">
              <CFView pulsate>
                <OrderPickupButton />
              </CFView>
              <CFLink href="http://onelink.to/cntchicken">
                <CFImage
                  src={downloadAppButton}
                  maxWidth="280px"
                  alt="C&T Fried Chicken Download App"
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          alignStart
        >
          <Header />
          <CFView column alignEnd justifyCenter w="100%" h="700px" pr="50px">
            <CFImage src={heroText} h="300px" alt="HeroText" mb="25px" />
            <CFView>
              <CFView pulsate>
                <OrderPickupButton />
              </CFView>
              <CFLink href="http://onelink.to/cntchicken">
                <CFImage
                  src={downloadAppButton}
                  maxWidth="300px"
                  alt="C&T Fried Chicken Download App"
                  hover
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
